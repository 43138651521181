import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { useAuth } from "../../authentication/AuthContext"
import SettingsIcon from "../../assets/settings.svg"
import LogoutIcon from "../../assets/door-exit.svg"

import styles from "./UserProfile.module.scss"
import { MutableRefObject, useRef, useState } from "react"
import useOnClickOutside from "../hooks/useOnClickOutside"

export default function UserProfile() {
  const { currentUser } = useAuth()
  const { isSignedIn, isSuperAdmin } = useAuth()
  const { t } = useTranslation()
  const ref = useRef() as MutableRefObject<HTMLDivElement>
  const [showDropdown, setShowDropdown] = useState(false)

  useOnClickOutside(ref, () => setShowDropdown(false))

  if (!isSignedIn) return null

  const links = [
    {
      url: "/organisation-config-settings",
      text: t("general.settings"),
      icon: SettingsIcon,
      condition: isSignedIn && isSuperAdmin,
    },
    {
      url: "/logout",
      text: t("auth.logout"),
      icon: LogoutIcon,
      condition: isSignedIn,
    },
  ]

  const getFirstLetter = (word: string): string => {
    return word.charAt(0).toUpperCase()
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.trigger}
        onClick={() => setShowDropdown((prev) => !prev)}
      >
        {getFirstLetter(currentUser?.name || currentUser?.email || "")}
      </div>
      <div
        ref={ref}
        className={`${styles.dropdown} ${
          showDropdown && styles.dropdownActive
        }`}
      >
        {links.map((link) =>
          link.condition ? (
            <Link
              key={link.url}
              to={link.url}
              className={styles.menuItemLinkWrapper}
            >
              <div key={link.icon} className={styles.menuItem}>
                <div className={styles.icon}>
                  <img src={link.icon} alt={link.text} />
                </div>
                <div className={styles.menuItemLabel}>{link.text}</div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  )
}
