import { Link } from "react-router-dom"

import Logo from "../../assets/single-letter-logo.svg"
import styles from "./Header.module.scss"

import UserProfile from "./UserProfile"

interface HeaderProps {
  title?: string
}

export default function Header({ title }: HeaderProps) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <Link to="/" className={styles.logoLink}>
            <img src={Logo} alt="Deed logo" className={styles.logo} />
          </Link>
        </div>
        <div className={styles.mainInfoContent}>
          <div className={styles.pageTitle}>{title}</div>
          <UserProfile />
        </div>
      </div>
    </div>
  )
}
